import { Injectable } from '@angular/core';
import { Router, CanActivate, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _authService: AuthService, private _router: Router) {}

  public canActivate(): boolean {
   if(localStorage.getItem('isConnected') !== 'true'){
    console.log('aaas')
    localStorage.clear()
    this._router.navigate(['/auth'])
    return false
   }
   return true
  }
}